import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import api from "lib/api";

const Personalization = ({ visibleFor, usePersonalization, children }) => {
  const [country, setCountry] = useState(null);
  let visibleForUser = true;

  if (Array.isArray(visibleFor)) {
    //If Section Component
    if (usePersonalization === true) {
      if (!visibleFor.includes("ALL")) {
        if (visibleFor.includes("ROW")) {
          visibleForUser = (country !== 'US' && country !== 'CA' && !visibleFor.includes("US,CA"))
        } else {
          visibleForUser = visibleFor.includes(country);
        }
      }
    }
  } else {
    //If any other components
    if (!visibleFor.includes("ALL")) {
      if (visibleFor.includes("ROW")) {
        visibleForUser = (country !== 'US' && country !== 'CA' && !visibleFor.includes("US,CA"))
      } else {
        const showForCountries = visibleFor.split(',')
        visibleForUser = showForCountries.includes(country)
      }
    }
  }

  useEffect(() => {
    async function getLocation() {
      const { country } = await api.location();
      setCountry(country);
    }
    getLocation();
  }, []);

  return <>{visibleForUser ? children : null}</>;
};

export default Personalization;

Personalization.propTypes = {
  visibleFor: PropTypes.node.isRequired,
  usePersonalization: PropTypes.bool,
  children: PropTypes.object.isRequired,
};
