import React, { useRef, useEffect, useState } from "react";
import cn from 'classnames'
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import { Icon } from "@soundtrackyourbrand/ui/src/Icon";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import * as styles from "./Table.module.scss";
import Personalization from "../../Personalization/Personalization";
import { graphql } from "gatsby";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";

const ComparisonTable = ({ block }) => {
  const title = block.table.title;
  const design = block.table.tableDesign;
  const table = block.table.table;
  const visibleFor = block.table.visibleFor;

  const tableRef = useRef();
  const [parentHasClass, setParentHasClass] = useState(true);

  const options = {
    replace: (domNode) => {
      if (
        domNode.type === "text" &&
        (domNode.data === "true" || domNode.data === "false")
      ) {
        if (domNode.data === "true") {
          return <Icon name="check" className={styles.check} />;
        } else if (domNode.data === "false") {
          return <Icon name="cross" className={styles.cross} />;
        }
      }
    },
  };

  useEffect(() => {
    if (tableRef.current.parentElement.nodeName === "MAIN") {
      setParentHasClass(true);
    } else {
      setParentHasClass(false);
    }
  }, []);

  return (
    <Personalization visibleFor={visibleFor}>
      <Section ref={tableRef} className={cn(!parentHasClass && styles.noSection, styles.popSection)}>
      {title && <StructuredTextRenderer data={title}/>}
        <div
          className={
            cn(design === 'Comparison Table'
              ? styles.popTable
              : ['subtle', styles.defaultTable])}
        >
          {parse(table, options)}
        </div>
      </Section>
    </Personalization>
  );
};

export default ComparisonTable;

ComparisonTable.propTypes = {
  comparisonTable: PropTypes.shape({
    title: PropTypes.object,
    comparisonTable: PropTypes.string.isRequired,
    tableDesign: PropTypes.string.isRequired,
    visibleFor: PropTypes.string.isRequired,
  }),
};

export const query = graphql`
  fragment TableReference on DatoCmsTablereference {
    model {
      apiKey
    }
    id: originalId
    table {
      title {
        value
      }
      visibleFor
      tableDesign
      table
    }
  }
`